import React from "react";

const BlogPage = () => {
  return (
<html lang='en'>
<head>
  <meta charSet="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <meta http-equiv="refresh" content="0; URL=https://www.thousandeyes.com/blog/suspicious-prefix-announcements-or-ris-peer-malfunction" />
  <title>Blog | Code BGP</title>
</head>

<body>
</body>

</html>
)
}

export default BlogPage
